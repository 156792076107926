import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Image from "next/image";
import React from "react";
import LiveHolders from "@/public/images/token-metrics/Live-Holders-seed.webp";
import MaxTotalSupply from "@/public/images/token-metrics/Max-Total-Supply-Seed.webp";
import FullyDilutedMarketCap from "@/public/images/token-metrics/Fully-Diluted-Market-Cap_Seed.webp";
import CirculatingSupply from "@/public/images/token-metrics/Circulating-Supply-Seed.webp";
import { useSelector } from "react-redux";
import { selecNftMarketPlaceSectionState } from "@/config/helpers/createReduxSelectors";
import { numberWithCommas } from "@/components/common/utiles";
import Link from "next/link";

function TokenMetrics() {
  const {
    errorMsgMarketPlaceInfo,
    loadingMarketPlaceInfo,
    errorMarketPlaceInfo,
    successMarketPlaceInfo,
    MarketPlaceInfo,
  } = useSelector(selecNftMarketPlaceSectionState);
  return (
    <>
      <div className="center">
        <div className="home__seedToken_metrics">
          <div className="home__nftMerketplace__title burst h3 is-center">
            <BurstPuckerImageTitles />
            Token Metrics
            <span style={{ marginLeft: "20px" }}></span>
            <BurstPuckerImageTitles />
          </div>
          <div className="box_holder">
            <div className="metric_box">
              <div className="metric_box_inner center_content">
                <Image
                  src={LiveHolders}
                  alt="Live-Holders-seed"
                  width={115}
                  height={134}
                  quality={95}
                />
                <div className="metrics-info">
                  <div className="metrics-title">Live Holders</div>
                  <div className="metric_number">
                    {numberWithCommas(191358)}
                  </div>
                </div>
              </div>
            </div>

            <div className="metric_box ">
              <div className="metric_box_inner center_content">
                <Image
                  src={MaxTotalSupply}
                  alt="Live-Holders-seed"
                  width={115}
                  height={134}
                  quality={95}
                />
                <div className="metrics-info">
                  <div className="metrics-title">Max Total Supply</div>
                  <div className="metric_number">
                    {MarketPlaceInfo &&
                      numberWithCommas(MarketPlaceInfo?.MaxTotalSupply)}
                  </div>
                </div>
              </div>
            </div>

            <div className="metric_box ">
              <div className="metric_box_inner center_content">
                <Image
                  src={FullyDilutedMarketCap}
                  alt="Live-Holders-seed"
                  width={115}
                  height={134}
                  quality={95}
                />
                <div className="metrics-info">
                  <div className="metrics-title">Fully Diluted Market Cap</div>
                  <div className="metric_number">
                    {MarketPlaceInfo &&
                      numberWithCommas(MarketPlaceInfo?.FullyDilutedMarketCap)}
                  </div>
                </div>
              </div>
            </div>

            <div className="metric_box ">
              <div className="metric_box_inner center_content">
                <Image
                  src={CirculatingSupply}
                  alt="Live-Holders-seed"
                  width={115}
                  height={134}
                  quality={95}
                />
                <div className="metrics-info">
                  <div className="metrics-title">Circulating Supply</div>
                  <div className="metric_number">
                    {MarketPlaceInfo &&
                      numberWithCommas(MarketPlaceInfo?.CirculatingSupply)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="home__nftMerketplace">
            <Link
              className="button-stroke home__twitterCommunity__tablet"
              href="/"
            >
              Explore More
              <i className="icon-chevron_up"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default TokenMetrics;
